@import url('https://fonts.googleapis.com/css2?family=Electrolize&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Assistant:wght@200..800&family=Rajdhani:wght@300;400;500;600;700&display=swap');

@font-face {
    font-family: 'Electrolize';
    src: url('https://fonts.googleapis.com/css2?family=Electrolize&display=swap');
}

@font-face {
    font-family: 'Assistant';
    src: url('https://fonts.googleapis.com/css2?family=Assistant:wght@200..800&display=swap');
}

@font-face {
    font-family: 'Rajdhani';
    src: url('https://fonts.googleapis.com/css2?family=Rajdhani:wght@300;400;500;600;700&display=swap');
}


body {
    line-height: 1;
}

h1, h2, h3, h4, h5, h6 {
    clear: both;
    font-weight: normal;
}

ol, ul {
}

blockquote {
    quotes: none;
}

blockquote:before, blockquote:after {
    content: '';
    content: none;
}

del {
    text-decoration: line-through;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

a img {
    border: none;
}

input, textarea {
    -webkit-appearance: none;
}

header {
    background-color: #14134E;
    padding: 10px 20px;
    border-bottom: 1px solid #A9B1DB; /* Белая линия под хедером */
}

.container {
    max-width: 1120px;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
}

.navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.logo {
    max-width: 250px;
    display: flex;
    align-items: center;
}

.logo img {
    margin-bottom: 3px;
    margin-right: 5px;
    width: 44px;
    height: 28px;
    top: 18px;
    left: 165px;
    gap: 0px;
    filter: brightness(0) invert(1); /* Белый цвет для логотипа */
}

.logo span {
    font-family: 'Electrolize', sans-serif;
    color: white;
    font-size: 18px;
    line-height: 18px;
    text-align: left;
}

.nav-links {
    list-style: none;
    display: flex;
    margin-left: 45px;
    padding: 0;
}

.nav-links li {
    margin: 0 24px;
}

.nav-links ul {
    display: flex;
}

.nav-links a {
    font-family: 'Assistant', sans-serif;
    color: white;
    font-weight: 200;
    text-decoration: none;
    font-size: 18px;
}

.contact-button {
    background-color: white !important;
    color: #14062B;
    font-family: 'Assistant', sans-serif;
    font-weight: 400;
    padding: 10px 20px;
    text-decoration: none;
    border-radius: 5px;
    font-size: 18px;
}

.contact-button:hover {
    background-color: #E8EAF6; /* Цвет фона кнопки при наведении */
}

.banner-container {
    background-color: #14134E !important;
    height: 850px;
    flex-direction: column;
    align-items: center;
    position: relative; /* Создаем контекст для абсолютного позиционирования вложенных элементов */
    margin: 0;
    padding: 0;
}

.banner-image-container {
    position: absolute; /* Абсолютное позиционирование контейнера с изображениями */
    right: 0; /* Выравниваем по правому краю */
    top: 0; /* Начинаем от верхнего края */
}

.content-wrapper {
    flex-direction: column;
    align-items: center;
}

.banner-image {
    width: 1052px;
    height: 847px;
    display: block;
    position: relative; /* Относительное позиционирование для задания z-index */
    z-index: 1; /* Устанавливаем ниже tags-image */
    background-image: url("assets/img.png");
}

.tags-image {
    width: 510px;
    height: 593px;
    position: absolute; /* Абсолютное позиционирование относительно banner-image */
    top: 97px; /* Позиционируем по вертикали относительно banner-image */
    right: 119px; /* Позиционируем по горизонтали относительно banner-image */
    z-index: 2; /* Устанавливаем выше banner-image */
    background-image: url("assets/tags.png");
    display: none;
}

.text-input-container {
    display: inline-block;
    flex-direction: column;
    align-items: center;
    color: white; /* Цвет текста */
    z-index: 2;
    position: absolute;
    justify-content: center;
    top: 120px;
}

.text-h2 {
    margin: 0 0 40px 0;
    width: 540px;
}

.text-input-container p {
    width: 730px;
    height: auto;
    gap: 0px;
    margin: 0 0 40px 0;
    font-family: 'Rajdhani', sans-serif;
    font-size: 70px;
    font-weight: 700;
    line-height: 80px;
    text-align: left;
}

.text-input-container a {
    height: 48px;
    gap: 0px;
    font-family: 'Assistant', sans-serif;
    font-size: 18px;
    font-weight: 200;
    line-height: 23.54px;
    text-align: left;
    color: #f1f1f1;
    padding-top: 35px;
}

.checkmark {
    margin-right: 10px;
    width: 24px !important;
    height: 24px !important;
    position: relative !important;
}

.input-container {
    display: flex;
    align-items: center;
    overflow: hidden; /* Обрезаем содержимое, выходящее за границы контейнера */
    width: 480px; /* Ширина всего контейнера */
    height: 55px;
    background-color: transparent; /* Прозрачный фон контейнера */
    padding: 8px;
    border-radius: 16px; /* Закругленные углы */
    border: 1px solid #bbbbbb;
}

.email-input {
    padding: 10px;
    font-size: 16px;
    font-family: 'Assistant', sans-serif;
    font-weight: 200;
    border: none; /* Убираем границы инпута */
    background: transparent; /* Прозрачный фон */
    flex-grow: 1; /* Инпут занимает всю оставшуюся ширину */
    color: white; /* Цвет текста */
}

.email-input::placeholder {
    color: #bbb; /* Цвет текста placeholder */
}

.email-input:focus {
    outline: none; /* Убираем стандартную границу при фокусе */
}

.submit-button {
    background-color: white !important;
    color: #14062B;
    font-family: 'Assistant', sans-serif;
    font-weight: 400;
    padding: 0 20px; /* Убираем верхний и нижний padding */
    text-decoration: none;
    border-radius: 8px;
    font-size: 18px;
    height: 40px; /* Кнопка по высоте контейнера */
    border: none; /* Убираем границы */
    cursor: pointer; /* Курсор-указатель */
}

.submit-button:hover {
    background-color: #E8EAF6 !important; /* Цвет фона кнопки при наведении */
}

.success-message {
    display: flex;
    align-items: center;
    color: green;
    margin-top: 10px;
    font-family: "Assistant", sans-serif;
    font-size: 17px;
}

.scope-container {
    height: 715px;
    background-color: #F8F8F8 !important;
}

.scope-title {
    width: 685px;
    padding-top: 100px;
}

.scope-title h1 {
    font-family: 'Rajdhani', sans-serif;
    font-size: 50px;
    font-weight: 700;
    text-align: left;
}

.scope-title p {
    font-family: 'Assistant', sans-serif;
    font-size: 18px;
    font-weight: 400;
    text-align: left;
    padding-top: 20px;
}

.scope-image {
    margin-top: 40px;
    width: 1160px;
    height: 386px;
    margin-left: -10px;
    position: absolute; /* Абсолютное позиционирование относительно banner-image */
    z-index: 2; /* Устанавливаем выше banner-image */
    background-image: url("assets/scope.png");
}

.lifecycle-container {
    height: 868px;
    background-color: #EBF0FB !important;
}

.lifecycle-title {
    width: 685px;
    padding-top: 100px;
}

.lifecycle-title h1 {
    font-family: 'Rajdhani', sans-serif;
    font-size: 50px;
    font-weight: 700;
    text-align: left;
}

.lifecycle-title p {
    font-family: 'Assistant', sans-serif;
    font-size: 18px;
    font-weight: 400;
    text-align: left;
    padding-top: 20px;
}

.lifecycle-image {
    margin-top: 40px;
    width: 1120px;
    height: 538px;
    display: flex;
    position: absolute; /* Абсолютное позиционирование относительно banner-image */
    z-index: 2; /* Устанавливаем выше banner-image */
}

@media (min-width: 1025px) {
    .block.open .block-content{
        margin-left: 15%;
    }
    .news-detail {
        width: 80%;
    }
}

.block {
    margin: 5px;
    transition: width 0.3s, height 0.3s;
    overflow: hidden;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.block-title {
    writing-mode: vertical-lr;
    transform: rotate(180deg);
    padding: 10px;
    text-align: center;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Rajdhani", sans-serif;
    font-size: 50px;
    font-weight: 700;
    line-height: 50px;
    background-image: linear-gradient(180deg, #FFFFFF -47.57%, #B1B1B1 160.42%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
}

.block-content {
    padding: 10px;
    display: none;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}

.block h2 {
    font-family: "Rajdhani", sans-serif;
    font-size: 50px;
    font-weight: 700;
    line-height: 50px;
    background-image: linear-gradient(180deg, #FFFFFF -47.57%, #B1B1B1 160.42%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    position: absolute; /* Добавьте абсолютное позиционирование */
    top: 0; /* Прикрепите к верхнему краю */
    left: 0; /* Прикрепите к левому краю */
}

.block-text {
    position: absolute; /* Добавьте абсолютное позиционирование */
    top: 80px; /* Прикрепите к верхнему краю */
    left: 0; /* Прикрепите к левому краю */
    font-family: "Assistant", sans-serif;
    font-size: 18px;
    font-weight: 300;
    line-height: 23.54px;
    text-align: left;
    background-image: linear-gradient(180deg, #FFFFFF -47.57%, #B1B1B1 160.42%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Два столбца */
    gap: 10px; /* Промежуток между столбцами */
}

.column {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 360px;
    margin-right: 15px;
}

.column p:last-child {
    border-bottom: none; /* Убираем белую полоску у последнего p в каждом столбце */
}

.block-text p {
    margin: 0;
    padding-bottom: 5px;
    padding-top: 5px;
    border-bottom: 1px solid #DADADA; /* Добавляем белую полоску под каждым p */
}

.block.open .block-title {
    display: none;
}

.block {
    width: 128.5px;
    height: 538px;
    padding: 40px;
    gap: 8px;
    border-radius: 8px;
}

.block.open {
    width: 1020px;
    height: 538px;
    padding: 40px;
    gap: 8px;
    border-radius: 8px;
}

.block.open .block-content {
    display: flex;
    width: 100%;
    height: 100%;
    margin-top: 10%;
}

#left {
    background-color: #0F3582;
}

#middle {
    background-color: #0890A4;
}

#right {
    background-color: #3B41CE;
}

.news-container {
    height: fit-content;
    background-color: #14134E !important;
}

.news-title {
    margin: 0 auto;
    align-items: center;
    align-content: center;
    width: 685px;
    padding-top: 50px;
    text-align: center;
}

.news-title h1 {
    background: linear-gradient(180deg, #FFFFFF -47.57%, #B1B1B1 160.42%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: 'Rajdhani', sans-serif;
    font-size: 50px;
    font-weight: 700;
}

.news-title p {
    font-family: 'Assistant', sans-serif;
    font-size: 18px;
    font-weight: 200;
    padding-top: 20px;
    color: white;
}

.news {
    width: 1120px;
    height: auto;
    margin-top: 40px;
    padding-bottom: 20px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    background-color: #14134E; /* Устанавливаем фон для news-item */
}

.news-item {
    width: 357px;
    height: 320px;
    gap: 16px;
    margin-right: 20px;
    margin-bottom: 40px;
}

.news-item img {
    width: 100%;
    height: 220px;
    gap: 0;
    border-radius: 8px 8px 8px 8px;
}

.news-item p {
    font-family: "Assistant", sans-serif;
    font-size: 22px;
    font-weight: 200;
    line-height: 26.4px;
    text-align: left;
    color: white;
    margin-top: 16px;
    margin-bottom: 16px;
}

.news-item a {
    font-family: "Assistant", sans-serif;
    font-size: 18px;
    font-weight: 200;
    line-height: 18px;
    text-align: left;
    color: #C9C9C9;
}

.show-more-button-container {
    display: flex;
    justify-content: center; /* Центрируем содержимое по горизонтали */
    padding-top: 40px;
}

.show-more {
    width: 139px;
    height: 40px;
    border-radius: 8px 8px 8px 8px;
    background-color: transparent;
    border: 1px solid white;
    color: #BABABA;
    font-family: "Assistant", sans-serif;
    font-size: 16px;
    font-weight: 200;
    text-align: center;
}

.show-more:hover {
    background-color: white;
    color: #14062B;
}

.news-container.expanded {
    height: auto;
}

.news-item.hidden {
    display: none;
}

.news-item {
    background-color: #14134E; /* Замените этот цвет на желаемый */
    margin-top: 20px;
}

.news-item img,
.news-item p,
.news-item a {
    background-color: #14134E; /* Замените этот цвет на желаемый */
}

.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 3;
}

.popup-content {
    background-color: white !important;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    max-width: 80%;
    position: relative; /* Добавляем позиционирование для внутренних элементов */
    padding: 40px 40px 40px 40px;
}

.close-icon {
    position: absolute;
    top: 30px;
    right: 40px;
    cursor: pointer;
    font-size: 35px;
    color: #271C6A;
    font-weight: 100;
}

.close-icon:hover {
    color: #333;
}

.popup-title {
    width: 420px;
    height: 53px;
    margin-bottom: 20px;
}

.popup-title h2 {
    font-family: "Assistant", sans-serif;
    font-size: 18px;
    font-weight: 700;
    line-height: 23.54px;
    text-align: left;
    color: #050522;
    padding-bottom: 5px;

}

.popup-title h3 {
    font-family: "Assistant", sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 25.2px;
    text-align: left;
    color: #C9C9C9;
}

.popup-content p {
    font-family: "Assistant", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 20.93px;
    text-align: left;
    color: #050522;
}

.testimonials-container {
    height: fit-content;
    background-color: #14134E !important;
    padding-top: 50px;
    padding-bottom: 1px;
}

.testimonials-title {
    margin: 0 auto;
    align-items: center;
    align-content: center;
    width: 685px;
    padding-top: 50px;
    text-align: center;
    color: #fff;
}

.testimonials-title h1 {
    font-family: "Rajdhani", sans-serif;
    font-size: 50px;
    font-weight: 700;
    line-height: 50px;
    text-align: center;
    margin-bottom: 15px;
}

.testimonials-title p {
    font-family: "Assistant", sans-serif;
    font-size: 18px;
    font-weight: 200;
    line-height: 23.54px;
    text-align: center;
    margin-bottom: 40px;
}

.testimonials-content {
    position: relative;
    background: linear-gradient(180deg, #191861 5.5%, #14134E 84.5%);
    width: 1120px;
    height: 358px;
    gap: 0px;
    border-radius: 8px;
    margin-bottom: 100px;
    overflow: hidden;
}

.testimonials-content::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 8px;
    padding: 1px;
    background: radial-gradient(82.79% 127.72% at 94.9% 25.45%, #574390 0%, #1A1244 41%, #574390 100%);
    -webkit-mask: linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0);
    -webkit-mask-composite: destination-out;
    mask-composite: exclude;
    z-index: 1;
}

.testimonials-content > * {
    position: relative;
    z-index: 2;
}

.testimonials-content-title {
    width: 414px;
    height: 167px;
    top: 40px;
    left: 40px;
    gap: 32px;
}

.testimonials-content-title h1 {
    font-family: "Assistant", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 20.93px;
    text-align: left;
    color: #565DF4;
    margin-bottom: 30px;
}

.testimonials-content-title p {
    font-family: 'Assistant', sans-serif;
    font-size: 32px;
    font-weight: 600;
    line-height: 38.4px;
    text-align: left;
    color: white;
}

.testimonials-content-post {
    width: 455px;
    height: 187px;
    left: 600px;
    gap: 13px;
    margin-top: -130px;
    transition: opacity 0.5s ease;
}

.testimonials-content-post a {
    font-family: "Assistant", sans-serif;
    font-size: 16px;
    font-weight: 200;
    line-height: 20.93px;
    text-align: left;
    color: white;
}

.post-title {
    display: flex;
    margin-top: 0;
    margin-bottom: 13px;
}

.post-title img {
    padding-right: 11px;
    height: 45px;
}

.post-title-text {
    font-family: "Assistant", sans-serif;
    font-size: 18px;
    line-height: 24px;
    text-align: left;
}

.post-title-text h1 {
    font-family: "Assistant", sans-serif;
    font-size: 18px;
    font-weight: 700;
    line-height: 23.54px;
    text-align: left;
    color: #fff;
}

.post-title-text p {
    color: #D9D9D9;
    font-weight: 300;
}

.navigation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 510px;
    top: 300px;
    position: absolute;
    right: 0;
}

.indicators {
    display: flex;
    gap: 8px;
    margin-top: 7px;
}

.indicator {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: rgba(140, 140, 140, 0.45);
    cursor: pointer;
}

.indicator.active {
    background-color: #fff;
}

.arrows {
    display: flex;
    gap: 10px;
    margin-top: -10px;
    margin-right: 40px;
}

.arrow {
    width: 32px;
    height: 32px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 24px;
    font-weight: lighter;
    color: #fff;
}

.arrow.left {
    background-image: url("./assets/left.png");
}

.arrow.right {
    background-image: url("./assets/right.png");
}


.work-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 100px;
    height: 454px;
    background-color: #F8F8F8 !important;
}

.work {
    position: relative;
    background-image: url(./assets/work.png);
    width: 1120px;
    height: 304px;
    border-radius: 8px;
    overflow: hidden;
}

.work::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 8px;
    padding: 1px;
    background: conic-gradient(from 180deg at 50% 50%, #F8F8F8 0deg, #888ec4 90deg, #F8F8F8 180deg, #9096c7 281.11deg, #F8F8F8 360deg);
    -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: destination-out;
    mask-composite: exclude;
    z-index: 1;
}

.header-button {
    display: none;
}

.work > * {
    position: relative;
    z-index: 2;
}

.team-container {
    height: 1100px;
    background-color: #F8F8F8 !important;
    padding-top: 50px;
    position: relative;
    z-index: 1;
}

.team-title {
    margin: 0 auto;
    align-items: center;
    align-content: center;
    width: 685px;
    padding-top: 50px;
    text-align: center;
    color: #090829;
}

.team-title h1 {
    font-family: "Rajdhani", sans-serif;
    font-size: 50px;
    font-weight: 700;
    line-height: 50px;
    text-align: center;
    margin-bottom: 15px;
}

.team-title p {
    font-family: "Assistant", sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 23.54px;
    text-align: center;
    margin-bottom: 40px;
}

.team-people {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
}

.team-people-item {
    position: relative;
    width: 265px; /* Adjust width if necessary */
    height: 300px; /* Adjust height if necessary */
    border-radius: 8px;
    overflow: hidden;
    background-color: #fff; /* Adjust if needed */
    /*box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); !* Optional shadow for better presentation *!*/
    margin-bottom: 20px;
}

.image-container {
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    overflow: hidden;
}

.team-people-item img {
    width: 100%;
    object-fit: cover;
    border-radius: 8px;
    position: relative;
    z-index: 1;
}

.text-overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 25px;
    height: 200px;
    background: linear-gradient(to top, rgb(232, 234, 240) 40%, rgba(255, 255, 255, 0));
    z-index: 2;
}

.text-overlay p {
    font-family: "Assistant", sans-serif;
    font-size: 18px;
    font-weight: 700;
    margin-top: 95px;
    padding-bottom: 7px;
    color: #000; /* Adjust text color for visibility */
}

.text-overlay p:nth-child(2) {
    /*margin: 0;*/
    margin-top: 0;
    font-family: "Assistant", sans-serif;
    font-weight: 300;
    font-size: 18px;
    padding-bottom: 4px;
    color: #000; /* Adjust text color for visibility */
    /*margin-bottom: 5px;*/
}

.text-overlay a {
    /*margin: 0;*/
    margin-top: 0;
    font-family: "Assistant", sans-serif;
    font-weight: 300;
    font-size: 18px;
    color: #000; /* Adjust text color for visibility */
    /*margin-bottom: 5px;*/
}

.text-overlay::after {
    content: url(./assets/arrow.png); /* Arrow symbol */
    font-size: 24px;
    font-weight: 200;
    position: absolute;
    right: 10px;
    bottom: 35px;
    color: #000; /* Adjust text color for visibility */
}

.inform-container {
    width: 1120px;
    height: 285px;
    border-radius: 12px;
    border: 1px solid; /* Specify border color */
    position: absolute;
    bottom: -136px; /* Move half of its height below the team-container */
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;
    justify-self: center;
    background-color: #14134E !important;
}

.inform-container img {
    z-index: 0;
    position: absolute; /* Абсолютное позиционирование контейнера с изображениями */
    right: 0; /* Выравниваем по правому краю */
    top: 0; /* Начинаем от верхнего края */
    width: 796.85px;
    height: 285px;
    border-radius: 12px;
    angle: -180deg;
}

.inform-text-input-container {
    display: inline-block;
    flex-direction: column;
    align-items: center;
    z-index: 2;
    position: absolute;
    justify-content: center;
    top: 30px;
    left: 40px;
}

.inform-text-h2 {
    margin: 0 0 20px 0;
    width: 369px;
}

.inform-text-input-container p {
    width: 710px;
    gap: 0px;
    color: #e0dede; /* Цвет текста */
    margin: 0 0 10px 0;
    font-family: 'Rajdhani', sans-serif;
    font-size: 50px;
    font-weight: 700;
    line-height: 80px;
    text-align: left;
}

.inform-text-input-container a {
    height: 48px;
    width: 369px;
    gap: 0px;
    font-family: 'Assistant', sans-serif;
    font-size: 18px;
    font-weight: 300;
    line-height: 23.54px;
    text-align: left;
    color: white;
}

footer {
    height: 340px;
    background-color: black;
    position: relative;
    z-index: 0;
}

.footer-logo {
    max-width: 250px;
    display: flex;
    align-items: center;
    padding-bottom: 14px;
}

.footer-logo img {
    margin-bottom: 3px;
    margin-right: 5px;
    width: 44px;
    height: 28px;
    top: 18px;
    left: 165px;
    gap: 0px;
    filter: brightness(0) invert(1); /* Белый цвет для логотипа */
}

.footer-logo span {
    font-family: 'Electrolize', sans-serif;
    color: white;
    font-size: 18px;
    line-height: 18px;
    text-align: left;
}

.motto {
    font-family: "Assistant", sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 23.54px;
    text-align: left;
    color: #EEEEEE;
    margin-left: 5px;
}

.footer-navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 220px;
}

.address {
    height: 48px;
    gap: 0px;
    font-family: "Assistant", sans-serif;
    font-size: 18px;
    font-weight: 300;
    line-height: 23.54px;
    text-align: left;
    color: #EEEEEE;
    margin-top: -20px;
}

.contact-items {
    display: flex;
    text-align: right;
    justify-content: right;
    margin-bottom: 20px;
}

.contact-items img {
    padding-right: 8px;
}

.email {
    color: #EEEEEE;
    margin-top: 8px;
    font-weight: 300;
}

.mobile {
    display: none;
}

.mobile-button {
    display: none;
}

.youTube {
    justify-self: center;
}

.media-link {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin-bottom: 10px;
    flex-direction: column;
}


@media (max-width: 1024px) {
    html, body {
        margin: 0;
        padding: 0;
        width: 100%;
        overflow-x: hidden;
    }

    .mobile-button {
        display: none;
    }

    .container {
        max-width: 720px;
        margin: 0 auto;
    }

    .lifecycle-image {
        flex-direction: column;
        align-items: stretch;
        width: 730px;
        height: 750px;
    }

    .block {
        width: 720px;
        height: 120px;
        flex-direction: row;
    }

    .block-title {
        writing-mode: horizontal-tb;
        transform: none;
        padding: 10px;
    }

    .block.open {
        width: 720px;
        height: 650px;
    }

    .lifecycle-container {
        height: 1000px;
    }

    .header-button {
        display: block;
    }

    .desktop {
        display: block;
    }

    .column {
        width: 292px;
        height: 100%;
    }

    .banner-image {
        width: 570px;
        height: 500px;
        display: block;
        position: relative; /* Относительное позиционирование для задания z-index */
        z-index: 1; /* Устанавливаем ниже tags-image */
        background-image: url("assets/banner-mini.png");
    }

    .tags-image {
        display: none;
    }

    .banner-container {
        height: 600px;
    }

    .text-input-container {
        top: 17%;
    }

    .text-input-container p {
        width: 415px;
        height: 240px;
        gap: 0px;
        font-family: 'Rajdhani', sans-serif;
        font-size: 40px;
        font-weight: 700;
        line-height: 50px;
        text-align: left;
    }

    .text-h2 {
        margin: 0 0 30px 0;
        width: 333px;
    }

    .tags {
        display: none;
    }

    .aerospace {
        top: 102px;
        left: 302px;
        background-image: url(./assets/tags/aerospace.png);
        width: 116px;
        height: 40px;
        position: absolute; /* Абсолютное позиционирование относительно banner-image */
        z-index: 2;
        background-repeat: no-repeat;

    }

    .energy {
        top: 363px;
        left: 436px;
        background-image: url(./assets/tags/energy.png);
        width: 100px;
        height: 40px;
        position: absolute; /* Абсолютное позиционирование относительно banner-image */
        z-index: 2;
        background-repeat: no-repeat;

    }

    .finteh {
        top: 173px;
        left: 382px;
        background-image: url(./assets/tags/finteh.png);
        width: 100px;
        height: 40px;
        position: absolute; /* Абсолютное позиционирование относительно banner-image */
        z-index: 2;
        background-repeat: no-repeat;

    }

    .logistics {
        top: 253px;
        left: 286px;
        background-image: url(./assets/tags/logistics.png);
        width: 100px;
        height: 40px;
        position: absolute; /* Абсолютное позиционирование относительно banner-image */
        z-index: 2;
        background-repeat: no-repeat;

    }

    .ngo {
        top: 25px;
        left: 345px;
        background-image: url(./assets/tags/ngo.png);
        width: 100px;
        height: 40px;
        position: absolute; /* Абсолютное позиционирование относительно banner-image */
        z-index: 2;
        background-repeat: no-repeat;

    }

    .robotics {
        top: 65px;
        left: 441px;
        background-image: url(./assets/tags/robotics.png);
        width: 100px;
        height: 40px;
        position: absolute; /* Абсолютное позиционирование относительно banner-image */
        z-index: 2;
        background-repeat: no-repeat;

    }

    .soft {
        top: 30px;
        left: 187px;
        background-image: url(./assets/tags/soft.png);
        width: 105px;
        height: 40px;
        position: absolute; /* Абсолютное позиционирование относительно banner-image */
        z-index: 2;
        background-repeat: no-repeat;

    }

    .telecom {
        top: 268px;
        left: 440px;
        background-image: url(./assets/tags/telecom.png);
        width: 100px;
        height: 40px;
        position: absolute; /* Абсолютное позиционирование относительно banner-image */
        z-index: 2;
        background-repeat: no-repeat;
    }

    .scope-container {
        height: 822px;
    }

    .scope-title {
        padding-top: 50px;
    }

    .scope-image {
        margin-top: 20px;
        width: 720px;
        height: 620px;
        position: absolute; /* Абсолютное позиционирование относительно banner-image */
        z-index: 2; /* Устанавливаем выше banner-image */
        background-image: url("assets/scope-mini.png");
        background-repeat: no-repeat;
    }

    .news {
        width: 720px;
        height: auto;
        margin-top: 10px;
        padding-bottom: 10px;
        margin-left: 20px;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        background-color: #14134E; /* Устанавливаем фон для news-item */
    }

    .news-item {
        width: 330px;
        height: 320px;
        gap: 16px;
        margin-right: 20px;
        margin-bottom: 40px;
    }

    .news-item img {
        width: 330px;
        height: 220px;
        gap: 0;
        border-radius: 8px 8px 8px 8px;
    }

    .testimonials-container {
        height: 750px;
    }

    .testimonials-content {
        position: relative;
        background: linear-gradient(180deg, #191861 5.5%, #14134E 84.5%);
        width: 680px;
        height: 482px;
        margin-left: 25px;
        gap: 0px;
        border-radius: 8px;
        margin-bottom: 100px;
        overflow: hidden;
        flex-direction: column;
        display: flex;
        align-items: center;
        user-select: none; /* Отключаем выделение текста для удобства свайпов */
    }

    .testimonials-title p {
        margin-bottom: 20px;
    }

    .testimonials-content-title {
        width: 600px;
        height: 113px;
        top: 40px;
        left: 0;
        gap: 32px;
        margin-bottom: 70px;
    }

    .testimonials-content-post {
        width: 587px;
        height: fit-content;
        left: 0;
        gap: 13px;
        margin-top: 0;
    }

    .navigation {
        display: flex;
        width: fit-content;
        /*top: 0;*/
        position: absolute;
        left: 45px;
        bottom: -80px;
    }

    .arrows {
        display: none;
    }

    .work-container {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 0;
        height: 404px;
        background-color: #F8F8F8;
    }

    .work {
        position: relative;
        background-image: url(./assets/work-mini.png);
        width: 680px;
        height: 272px;
        border-radius: 8px;
        overflow: hidden;
        margin-left: 5px;
    }

    .team-container {
        height: 1300px;
        background-color: #F8F8F8;
        padding-top: 0;
        position: relative;
        z-index: 1;
    }

    .team-people {
        margin-left: 10px;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }

    .team-people-item {
        position: relative;
        width: 332px;
        height: 300px;
        border-radius: 8px;
        overflow: hidden;
        background-color: #fff;
        margin-bottom: 20px;
        margin-right: 10px;
    }

    .inform-container {
        width: 680px;
    }

    .inform-container img {
        z-index: 0;
        position: absolute;
        right: 0;
        top: 0;
        width: 578px;
        height: 285px;
        border-radius: 12px;
    }

    .nav-links {
        position: fixed;
        right: 0;
        top: 0;
        height: 100%;
        width: 100%;
        background-color: #14134E;
        flex-direction: column;
        transform: translateX(100%);
        transition: transform 0.3s ease-in-out;
        z-index: 1000;
    }

    .nav-links.open {
        transform: translateX(0);
    }

    .nav-links ul {
        list-style: none;
        padding: 80px 0 0 30px;
        margin: 0;
        display: flex;
        flex-direction: column;
        text-align: left;
        width: 100%;
    }

    .nav-links li {
        margin: 20px 0;
    }

    .nav-links a {
        font-size: 32px;
        margin: 0;
        padding-top: 5px;
        padding-bottom: 5px;
        position: relative; /* Для позиционирования псевдоэлемента */
    }

    .nav-links a:not(.contact-button):after {
        content: ''; /* Создаем псевдоэлемент */
        position: absolute;
        bottom: 0; /* Помещаем псевдоэлемент внизу */
        left: 0;
        width: 650px; /* Увеличиваем горизонтальную длину на 20px */
        height: 1px; /* Толщина границы */
        background-color: #A9B1DB;
    }

    .hamburger {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        width: 16px;
        height: 12px;
        background: transparent;
        border: none;
        cursor: pointer;
        z-index: 1001;
    }

    .bar {
        width: 100%;
        height: 1px;
        background-color: white;
        border-radius: 10px;
        transition: transform 0.3s ease; /* Добавляем анимацию */
    }

    .open .bar:nth-child(2) {
        transform: scaleX(0); /* Прячем центральный бар */
    }

    .open .bar:nth-child(1) {
        transform: rotate(45deg) translateY(5px); /* Поворачиваем верхний бар */
    }

    .open .bar:nth-child(3) {
        transform: rotate(-45deg) translateY(-5px); /* Поворачиваем нижний бар */
    }

    .nav-links .contact-button {
        width: 120px;
        background-color: white;
        color: #14062B;
        font-family: 'Assistant', sans-serif;
        font-weight: 400;
        padding: 10px 20px;
        text-decoration: none;
        border-radius: 5px;
        font-size: 18px;
        margin: 35px 0 0 30px;
    }

    .logo-in-menu {
        max-width: 250px;
        display: flex;
        align-items: center;
        z-index: 2;
        position: absolute;
        justify-content: center;
        top: 20px;
        left: 30px;
    }

    .logo-in-menu img {
        margin-bottom: 3px;
        margin-right: 5px;
        width: 44px;
        height: 28px;
        top: 18px;
        left: 165px;
        filter: brightness(0) invert(1); /* Белый цвет для логотипа */
    }

    .logo-in-menu span {
        font-family: 'Electrolize', sans-serif;
        color: white;
        font-size: 18px;
        line-height: 18px;
        text-align: left;
    }

    .mobile {
        display: block;
        text-align: right;
        width: 244px;
        margin-bottom: 25px;
        margin-right: 10px;
    }

    .email {
        width: 244px;
        text-align: right;
        margin-left: 50px;
    }

    footer {
        height: 400px;
    }

    .footer-navbar {
        padding-top: 200px;
    }

    .motto {
        font-weight: 300;
    }

    .footer-logo-container {
        margin-bottom: 55px;
    }
}

@media (max-width: 600px) {
    body {
        overflow-x: hidden;
    }

    .container {
        width: 94%;
        margin: 0 auto 0 20px;
    }

    .lifecycle-image {
        flex-direction: column;
        align-items: stretch;
        width: 97%;
        height: 680px;
        left: 0;
    }

    .block {
        width: 100%;
        height: 120px;
        flex-direction: row;
    }

    .block-title {
        writing-mode: horizontal-tb;
        transform: none;
        padding: 10px;
    }

    .block.open {
        width: 100%;
        height: 850px;
    }

    .block-content {
        top: -20px;
        left: -20px;
        bottom: 100px;
    }

    .block-content h2 {
        font-size: 32px;
        line-height: 32px;
    }

    .block-content p {
        font-size: 16px;
    }

    .block-text {
        top: 40px;
        width: 340px;
    }

    .lifecycle-title {
        padding-top: 40px;
        width: 375px;
    }

    .lifecycle-title h1 {
        font-size: 32px;
    }

    .lifecycle-title p {
        font-size: 16px;
    }

    .lifecycle-container {
        height: 900px;
    }

    .column {
        width: 150px;
        height: 100%;
    }

    .banner-image {
        width: 261px;
        height: 239px;
        display: block;
        position: absolute; /* Абсолютное позиционирование для размещения в правом верхнем углу */
        top: 0; /* Закрепляем вверху */
        right: 0; /* Закрепляем справа */
        z-index: 1; /* Устанавливаем ниже tags-image, если нужно */
        background-image: url("assets/banner-micro.png");
        background-repeat: no-repeat;
    }

    .header-button {
        display: block;
    }

    .tags-image {
        display: none;
    }

    .banner-container {
        height: 390px;
    }

    .text-input-container {
        top: 9%;
    }

    .text-input-container p {
        width: 316px;
        height: 120px;
        gap: 0px;
        font-family: 'Rajdhani', sans-serif;
        font-size: 30px;
        font-weight: 700;
        line-height: 32px;
        text-align: left;
        margin-bottom: 18px;
    }

    .text-input-container a {
        margin-bottom: 15px;
        width: 343px;
    }

    .input-container {
        width: 343px;
    }

    .text-h2 {
        margin: 0 0 30px 0;
        width: 343px;
    }

    .contact-button {
        padding: 8px 16px 8px 16px;
        border-radius: 8px;
        font-size: 14px;
    }

    .mobile-button {
        display: block;
        width: 130px;
        height: 37px;
        margin-top: 20px;
        font-size: 13px;
        font-weight: 400;
    }

    .tags {
        width: 300px;
        margin-left: 272px;
        display: none;
    }

    .aerospace {
        top: 430px;
        left: 210px;
        background-image: url(./assets/tags/aerospace-mini.png);
        width: 116px;
        height: 40px;
        position: absolute; /* Абсолютное позиционирование относительно banner-image */
        z-index: 2;
        background-repeat: no-repeat;
    }

    .energy {
        top: 500px;
        left: 360px;
        background-image: url(./assets/tags/energy-mini.png);
        width: 100px;
        height: 40px;
        position: absolute; /* Абсолютное позиционирование относительно banner-image */
        z-index: 2;
        background-repeat: no-repeat;

    }

    .finteh {
        top: 483px;
        left: 455px;
        background-image: url(./assets/tags/finteh-mini.png);
        width: 100px;
        height: 40px;
        position: absolute; /* Абсолютное позиционирование относительно banner-image */
        z-index: 2;
        background-repeat: no-repeat;

    }

    .logistics {
        top: 530px;
        left: 430px;
        background-image: url(./assets/tags/logistics-mini.png);
        width: 100px;
        height: 40px;
        position: absolute; /* Абсолютное позиционирование относительно banner-image */
        z-index: 2;
        background-repeat: no-repeat;

    }

    .ngo {
        top: 445px;
        left: 345px;
        background-image: url(./assets/tags/ngo-mini.png);
        width: 76px;
        height: 26px;
        position: absolute; /* Абсолютное позиционирование относительно banner-image */
        z-index: 2;
        background-repeat: no-repeat;

    }

    .robotics {
        top: 430px;
        left: 415px;
        background-image: url(./assets/tags/robotics-mini.png);
        width: 100px;
        height: 40px;
        position: absolute; /* Абсолютное позиционирование относительно banner-image */
        z-index: 2;
        background-repeat: no-repeat;

    }

    .soft {
        top: 530px;
        left: 220px;
        background-image: url(./assets/tags/soft-mini.png);
        width: 75px;
        height: 26px;
        position: absolute; /* Абсолютное позиционирование относительно banner-image */
        z-index: 2;
        background-repeat: no-repeat;

    }

    .telecom {
        top: 480px;
        left: 260px;
        background-image: url(./assets/tags/telecom-mini.png);
        width: 70px;
        height: 26px;
        position: absolute; /* Абсолютное позиционирование относительно banner-image */
        z-index: 2;
        background-repeat: no-repeat;
    }

    .scope-container {
        height: 950px;
    }

    .scope-title {
        padding-top: 20px;
        width: calc(100% - 20px); /* Уменьшаем ширину, чтобы учитывать отступы */
        margin: 0 auto; /* Центрируем элемент */
        font-size: 16px;
        box-sizing: border-box; /* Учитываем отступы в общей ширине */
    }

    .scope-title p {
        font-size: 16px;
    }

    .scope-title h1 {
        font-size: 32px;
    }

    .scope-image {
        margin-top: 20px;
        width: 100%; /* Устанавливаем ширину 100% */
        height: 815px; /* Высота будет подстраиваться под содержимое */
        position: relative; /* Отменяем абсолютное позиционирование */
        background-image: url("assets/scope-micro.png");
        background-repeat: no-repeat;
        background-size: contain; /* Изображение масштабируется, сохраняя пропорции */
        background-position: center;
    }

    .input-container button {
        display: none;
    }

    .inform-text-input-container button {
        margin-top: 16px;
    }

    .news-container {
        justify-content: center; /* Центрируем по горизонтали */
    }

    .news {
        width: 92%; /* Уменьшаем ширину, чтобы учитывать отступы */
        height: auto;
        margin-top: 10px;
        padding-bottom: 10px;
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        background-color: #14134E; /* Устанавливаем фон для news-item */
    }

    .news-title {
        /*margin: 0 0 0 3%;*/
        align-items: center;
        align-content: center;
        width: 343px;
        padding-top: 40px;
        text-align: center;
    }

    .news-title h1 {
        font-size: 32px;
        margin-bottom: -10px;
    }

    .news-title p {
        font-size: 18px;
    }

    .news-item {
        width: 330px;
        height: 320px;
        gap: 16px;
        margin-left: auto; /* Положение слева будет автоматически выравниваться по центру */
        margin-right: auto;
        /*margin-right: 20px;*/
        margin-bottom: 40px;
    }

    .news-item img {
        width: 375px;
        height: 220px;
        gap: 0;
        border-radius: 8px 8px 8px 8px;
    }

    .show-more-button-container {
        margin-left: -5%;
    }

    .testimonials-container {
        padding-top: 0;
        height: 440px;
    }

    .news-item p {
        width: calc(100% - 25px); /* Уменьшаем ширину, чтобы учитывать отступы */
        font-size: 22px;
    }

    .testimonials-content {
        margin-left: 0;
        position: relative;
        background: linear-gradient(180deg, #191861 5.5%, #14134E 84.5%);
        width: 97%; /* Уменьшаем ширину, чтобы учитывать отступы */
        height: 256px;
        gap: 0px;
        left: -0.1rem;
        border-radius: 8px;
        margin-bottom: 100px;
        overflow: hidden;
        flex-direction: column;
        display: flex;
        align-items: center;
        user-select: none; /* Отключаем выделение текста для удобства свайпов */
    }

    .testimonials-title {
        width: 375px;
    }

    .testimonials-title p {
        margin-bottom: 25px;
        font-size: 18px;
    }

    .testimonials-title h1 {
        font-size: 32px;
    }

    .testimonials-content-title {
        width: 333px;
        height: 113px;
        top: 20px;
        left: 0;
        gap: 32px;
        margin-bottom: 50px;
    }

    .testimonials-content-title h1 {
        margin-bottom: 10px;
    }

    .testimonials-content-title p {
        font-size: 24px;
        line-height: 28px;
    }

    .testimonials-content-post {
        width: 333px;
        height: fit-content;
        top: 0;
        left: 0;
        gap: 13px;
        margin-top: 0;
    }

    .testimonials-content-post a {
        display: none;
    }

    .post-title img {
        padding-bottom: 8px;
        padding-right: 11px;
        height: 40px;
        width: 40px;
    }

    .navigation {
        display: flex;
        width: fit-content;
        top: 120px;
        position: absolute;
        left: 15px;
    }

    .arrows {
        display: none;
    }

    .work-container {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 0;
        height: 404px;
        background-color: #F8F8F8;
    }

    .work {
        position: relative;
        background-image: url(./assets/work-micro.png);
        width: 350px;
        height: 315px;
        border-radius: 8px;
        overflow: hidden;
        background-repeat: no-repeat;
        background-size: contain; /* Изображение масштабируется, сохраняя пропорции */
        background-position: center;
    }

    .team-container {
        background-color: #F8F8F8;
        padding-top: 0;
        position: relative;
        z-index: 1;
        height: fit-content;
        padding-bottom: 180px;
    }

    .team-title {
        padding-top: 30px;
        width: 100%; /* Обеспечиваем, что заголовок займет всю ширину контейнера */
        text-align: center; /* Центрируем текст */
        box-sizing: border-box; /* Обеспечиваем правильное вычисление ширины */
        padding-left: 15px; /* Добавляем отступы */
        padding-right: 15px;
    }

    .team-title h1 {
        font-size: 32px;
        margin-bottom: 0;
    }

    .team-title p {
        font-size: 16px;
        margin-bottom: 20px;
    }

    .team-people {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(280px, 1fr)); /* Изменено значение minmax */
        gap: 20px;
        margin-left: 0;
    }

    .team-people-item {
        position: relative;
        display: flex;
        align-items: center;
        width: 100%;
        height: 180px;
        border-radius: 8px;
        overflow: hidden;
        background-color: #fff;
        margin-bottom: 20px;
        z-index: 1;
    }

    .image-container {
        flex-shrink: 0;
        width: 180px;
        height: 100%;
        position: relative;
    }

    .image-container img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        border-radius: 8px 0 0 8px;
    }

    .text-overlay {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 60px; /* Matches the width of the image-container */
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 20px;
        z-index: 2;
        color: #000;
        background: linear-gradient(to right, rgba(255, 255, 255, 0) 10%, rgba(232, 234, 240, 0.98) 27%);
    }

    .text-overlay p,
    .text-overlay a {
        margin: 0 0 0 100px;
        padding: 0;
        font-size: 18px;
        font-weight: bold;
        z-index: 3;
        color: #000;
    }

    .text-overlay p {
        margin-top: 20px;
        width: 130px;
    }

    .text-overlay a {
        color: #000;
        text-decoration: none;
        font-weight: normal;
    }

    .text-overlay::after {
        content: url(./assets/arrow.png);
        font-size: 24px;
        font-weight: 200;
        position: absolute;
        right: 28px;
        top: 25px;
        color: #000;
    }

    .inform-container {
        width: 100%;
        height: 262px;
    }

    .inform-container img {
        z-index: 0;
        position: absolute;
        right: 0;
        top: 0;
        width: 174px;
        height: 231px;
        border-radius: 12px;
    }

    .inform-text-input-container {
        display: inline-block;
        flex-direction: column;
        align-items: center;
        z-index: 2;
        position: absolute;
        justify-content: center;
        top: 0;
        left: 20px;
    }

    .inform-text-input-container p {
        width: 290px;
        font-size: 32px;
        margin-bottom: -16px;
    }

    .inform-text-input-container a {
        font-size: 16px;
    }

    .inform-text-h2 {
        width: 290px;
    }

    .nav-links {
        position: fixed;
        right: 0;
        top: 0;
        height: 100%;
        width: 100%;
        background-color: #14134E;
        flex-direction: column;
        transform: translateX(100%);
        transition: transform 0.3s ease-in-out;
        z-index: 1000;
    }

    .nav-links.open {
        transform: translateX(0);
        max-width: 425px;
    }

    .nav-links ul {
        list-style: none;
        padding: 80px 0 0 30px;
        margin: 0;
        display: flex;
        flex-direction: column;
        text-align: left;
        width: 100%;
    }

    .nav-links li {
        margin: 20px 0;
    }

    .nav-links a {
        font-size: 24px;
        margin: 0;
        padding-top: 5px;
        padding-bottom: 5px;
        position: relative; /* Для позиционирования псевдоэлемента */
    }

    .nav-links a:not(.contact-button):after {
        content: ''; /* Создаем псевдоэлемент */
        position: absolute;
        bottom: 0; /* Помещаем псевдоэлемент внизу */
        left: 0;
        width: 360px; /* Увеличиваем горизонтальную длину на 20px */
        height: 1px; /* Толщина границы */
        background-color: #A9B1DB;
    }

    .hamburger {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        width: 16px;
        height: 12px;
        background: transparent;
        border: none;
        cursor: pointer;
        z-index: 1001;
    }

    .bar {
        width: 100%;
        height: 1px;
        background-color: white;
        border-radius: 10px;
        transition: transform 0.3s ease; /* Добавляем анимацию */
    }

    .open .bar:nth-child(2) {
        transform: scaleX(0); /* Прячем центральный бар */
    }

    .open .bar:nth-child(1) {
        transform: rotate(45deg) translateY(5px); /* Поворачиваем верхний бар */
    }

    .open .bar:nth-child(3) {
        transform: rotate(-45deg) translateY(-5px); /* Поворачиваем нижний бар */
    }


    .nav-links .contact-button {
        width: 120px;
        background-color: white;
        color: #14062B;
        font-family: 'Assistant', sans-serif;
        font-weight: 400;
        padding: 10px 20px;
        text-decoration: none;
        border-radius: 5px;
        font-size: 16px;
        margin: 35px 0 0 30px;
    }

    .logo {
        margin-left: -15px;
    }

    .logo-in-menu {
        max-width: 250px;
        display: flex;
        align-items: center;
        z-index: 2;
        position: absolute;
        justify-content: center;
        top: 20px;
        left: 30px;
    }

    .logo-in-menu img {
        margin-bottom: 3px;
        margin-right: 5px;
        width: 44px;
        height: 28px;
        top: 18px;
        left: 165px;
        filter: brightness(0) invert(1); /* Белый цвет для логотипа */
    }

    .logo-in-menu span {
        font-family: 'Electrolize', sans-serif;
        color: white;
        font-size: 18px;
        line-height: 18px;
        text-align: left;
    }

    .desktop {
        display: none;
    }

    .mobile {
        display: block;
        text-align: left;
        width: 244px;
        margin-bottom: 40px;
        margin-right: 10px;
        font-size: 16px;
    }

    .email {
        width: 244px;
        text-align: left;
        margin-left: 0;
    }

    .contact-items {
        display: flex;
        text-align: left;
        justify-content: left;
        margin-bottom: 25px;
    }

    footer {
        height: 550px;
    }

    .footer-navbar {
        padding-top: 155px;
        align-items: start;
        flex-direction: column;
    }


    .motto {
        font-weight: 300;
        font-size: 16px;
        margin-left: 0;
    }

    .footer-logo-container {
        margin-bottom: 25px;
    }

    .footer-logo {
        padding-bottom: 8px;
    }

    .policy {
        margin-bottom: 50px;
    }
}

/* NewsDetail.css */
.news-detail-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    background-color: #f9f9f9;
}

.news-detail {
    width: 100%;
    background: white;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    text-align: center;
}

.news-detail-image {
    width: 100%;
    height: auto;
    border-radius: 10px;
}

.news-detail-title {
    font-size: 2em;
    margin: 20px 0;
    color: #333;
}

.news-detail-date {
    color: #999;
    margin-bottom: 20px;
}

.news-detail-text {
    font-size: 1.2em;
    line-height: 1.6;
    color: #666;
    text-align: left;
}


.admin-panel-container h1 {
    text-align: center;
    font-size: 32px;
    margin-bottom: 20px;
    margin-top: 20px;
}

.cookie-popup {
    height: 80px;
    display: none;
    position: fixed;
    bottom: 15px;
    left: 10px;
    right: 10px;
    background-color: white;
    color: black;
    padding: 20px;
    text-align: center;
    z-index: 1000;
    border-radius: 8px 8px 8px 8px;
}

.cookie-popup.show {
    display: flex;
}

.cookie-popup-content {
    display: flex;
    text-align: left;
    max-width: 1440px;
    height: 80px;
    margin: 0 auto;
    font-size: 18px;
    font-family: "Assistant", sans-serif;
}

.cookie-popup-content a {
    border-bottom: 1px solid black;
}

.cookie-popup button {
    width: 110px;
    padding: 10px;
    cursor: pointer;
    font-size: 18px;
    font-family: "Assistant", sans-serif;
}

.decline {
    margin-left: 40px;
    height: 40px;
    border-radius: 8px;
    border: 1px solid black;
}

.button-block {
    display: flex;
}

.accept {
    margin-left: 10px;
    height: 40px;
    left: 983px;
    padding: 8px 16px 8px 16px;
    gap: 10px;
    border-radius: 8px;
    background-color: black;
    color: white;
}

/* Адаптивные стили */
@media (max-width: 1024px) {
    .news-detail {
        padding: 15px;
    }

    .news-detail-title {
        font-size: 1.5em;
    }

    .news-detail-text {
        font-size: 1em;
    }

    .cookie-popup.show {
        height: 130px;
    }
}

@media (max-width: 600px) {
    .news-detail {
        padding: 10px;
    }

    .news-detail-title {
        font-size: 1.2em;
    }

    .news-detail-text {
        font-size: 0.9em;
    }

    .cookie-popup.show {
        height: 200px;
        display: flex;
        flex-direction: column; /* Изменено направление группировки на колонки */
        align-items: center; /* Выравнивание элементов по центру */
    }

    .cookie-popup-content {
        flex-direction: column;
    }

    .button-block {
        margin-top: 30px;
    }

    .cookie-popup-content a {
        height: 120px;
        width: 343px;
    }

    .decline {
        margin-left: 0;
    }
}

.podcast {
    display: flex;
    text-align: center;
}

.podcast img {
    width: 40px;
}

.podcast p {
    margin-top: 10px;
}

.policy {
    height: 74px;
}

.address.policy:first-child {
    padding-top: 5px;
}

.privacy-footer {
    height: 100px;
}

.privacy-footer .footer-navbar {
    padding-top: 10px;
}

.legal-footer {
    height: 100px;
}

.legal-footer .footer-navbar {
    padding-top: 10px;
}

.privacy:hover {
    text-decoration: underline; /* Добавляет подчеркивание при наведении */
    cursor: pointer; /* Изменяет курсор на указатель при наведении */
}

@media (min-width: 1025px) {
    .news-detail {
        width: 75%;
    }
}